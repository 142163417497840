<template>
    <div class="authorization-forms">
        <CustomerForgotPasswordPanel
            v-if="isResetPasswordForm"
            @reset-success="$emit('reset-success', $event)"
            @click-reset-password-button="$emit('click-reset-password-button')"
            @click-send-magic-link-button="$emit('click-send-magic-link-button')"
            @magic-link-sent="$emit('magic-link-sent')"
            @close="$emit('close')"
            @magic-link-back-to-login="$emit('magic-link-back-to-login')"
            @magic-link-show-sent-information="$emit('magic-link-show-sent-information')"
            @magic-link-go-to-home="$emit('magic-link-go-to-home')"
            @magic-link-show-missing-link-information="
                $emit('magic-link-show-missing-link-information')
            "
            @magic-link-missing-link-click="$emit('magic-link-missing-link-click')"
        />

        <template v-else>
            <SegmentedControl :selected="formId" class="controlls" @select="selectTab($event)">
                <template #default="{ props, on }">
                    <SegmentedControlButton
                        v-for="{ id, label } in TABS"
                        :key="id"
                        :value="id"
                        v-bind="props"
                        class="segmented-button"
                        v-on="on"
                    >
                        {{ label }}
                    </SegmentedControlButton>
                </template>
            </SegmentedControl>

            <div class="forms">
                <transition name="fade" mode="out-in">
                    <component
                        :is="tabComponent"
                        :show-wishlist-login-hint="showWishlistLoginHint"
                        :initial-email="initialEmail"
                        @login-success="onLoginSuccess($event)"
                        @register-success="$emit('register-success', $event)"
                        @click-register-button="$emit('click-register-button', $event)"
                        @click-login-button="$emit('click-login-button', $event)"
                        @open-reset-password-form="setFormId(FORGOT_PASSWORD)"
                    />
                </transition>
            </div>
        </template>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA } from '@configs/modals';

import {
    LOGIN_COMPONENT_NAME,
    REGISTER_COMPONENT_NAME,
    AUTHORIZATION_FORMS_IDS,
} from '@types/CustomerModal';

import Loader from '@atoms/Loader/Loader';

import {
    SegmentedControl,
    SegmentedControlButton,
} from '@modivo-ui/components/SegmentedControl/v1';

const { mapState: mapCustomerState } = createNamespacedHelpers('customer');

export default {
    name: 'AuthorizationForms',

    components: {
        SegmentedControl,
        SegmentedControlButton,
        CustomerLoginPanel: () => ({
            component: import(
                /* webpackChunkName: "login-panel" */
                '@organisms/CustomerLoginPanel/CustomerLoginPanel'
            ),
            loading: Loader,
        }),
        CustomerRegisterPanel: () => ({
            component: import(
                /* webpackChunkName: "register-form" */
                '@organisms/CustomerRegisterPanel/CustomerRegisterPanel'
            ),
            loading: Loader,
        }),
        CustomerForgotPasswordPanel: () => ({
            component: import(
                /* webpackChunkName: "customer-forgot-password-panel" */
                '@organisms/CustomerForgotPasswordPanel/CustomerForgotPasswordPanel'
            ),
            loading: Loader,
        }),
    },

    props: {
        formId: {
            type: String,
            default: AUTHORIZATION_FORMS_IDS.LOGIN,
            validator: formId => Object.values(AUTHORIZATION_FORMS_IDS).includes(formId),
        },

        showWishlistLoginHint: {
            type: Boolean,
            default: false,
        },

        initialEmail: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapCustomerState(['isCustomerSubscribedToNewsletter']),

        tabComponent() {
            return this.TABS_FORMS[this.formId] || null;
        },

        isResetPasswordForm() {
            return this.formId === AUTHORIZATION_FORMS_IDS.FORGOT_PASSWORD;
        },
    },

    beforeCreate() {
        const { LOGIN, REGISTER, FORGOT_PASSWORD } = AUTHORIZATION_FORMS_IDS;

        this.FORGOT_PASSWORD = FORGOT_PASSWORD;

        this.TABS_FORMS = {
            [LOGIN]: LOGIN_COMPONENT_NAME,
            [REGISTER]: REGISTER_COMPONENT_NAME,
        };
        this.TABS = [
            {
                label: this.$t('I have an account'),
                id: LOGIN,
            },
            {
                label: this.$t('I dont have an account'),
                id: REGISTER,
            },
        ];
    },

    methods: {
        selectTab(id) {
            if (this.formId === id) {
                return;
            }

            this.setFormId(id);
        },

        setFormId(id) {
            this.$emit('set-form-id', id);
        },

        async onLoginSuccess({ isRegistration, type }) {
            if (isRegistration) {
                if (!this.isCustomerSubscribedToNewsletter) {
                    this.$modals.open(MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA);
                }
            }

            this.$emit('login-success', { isRegistration, type });
        },
    },
};
</script>

<style lang="scss" scoped>
.authorization-forms {
    @apply py-ds-6 px-ds-4 bg-ds-container-primary;

    .segmented-button {
        @apply flex-grow;
    }

    .forms {
        @apply mt-ui-6;
    }

    @screen lg {
        @apply p-ds-6;
    }
}
</style>
