import debounce from 'lodash.debounce';

import { CLICK_DEBOUNCE_TIME } from '@configs/analytics-events';

export default {
    methods: {
        handleClickEvent: debounce(
            function (event) {
                if (!event) {
                    return;
                }

                this.$analytics.emit(event);
            },
            CLICK_DEBOUNCE_TIME,
            { leading: true }
        ),
    },
};
